import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Export`}</h1>
    <p>{`Aug 2020: Data export is temporarily disabled in the main instance of pol.is as we reconfigure how emails are sent to accommodate third party deployments.`}</p>
    <p>{`The data export for a given `}<a parentName="p" {...{
        "href": "/conversation",
        "title": "conversation"
      }}>{`conversation`}</a>{` is in CSV form.`}</p>
    <h3>{`Tables`}</h3>
    <ul>
      <li parentName="ul">{`Summary`}
        <ul parentName="li">
          <li parentName="ul">{`Basic summary statistics about the conversation, including number of voters, commenters, conversation topic, etc`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Stats History`}
        <ul parentName="li">
          <li parentName="ul">{`A timeline of `}<inlineCode parentName="li">{`n-votes`}</inlineCode>{`, `}<inlineCode parentName="li">{`n-comments`}</inlineCode>{`, `}<inlineCode parentName="li">{`n-visitors`}</inlineCode>{`, `}<inlineCode parentName="li">{`n-voters`}</inlineCode>{` & `}<inlineCode parentName="li">{`n-commenters`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/participants",
          "title": "participants"
        }}>{`participants`}</a>{` `}<a parentName="li" {...{
          "href": "/votes",
          "title": "votes"
        }}>{`votes`}</a>{``}
        <ul parentName="li">
          <li parentName="ul">{`a table of participants (as rows), with data such as `}<inlineCode parentName="li">{`participant`}</inlineCode>{` (numeric id), `}<inlineCode parentName="li">{`xid`}</inlineCode>{` (if export was generated with the `}<inlineCode parentName="li">{`-X`}</inlineCode>{` flag at command line; not currently supported in UI export), `}<inlineCode parentName="li">{`group-id`}</inlineCode>{` (the K-means clustering assignment), `}<inlineCode parentName="li">{`n-comments`}</inlineCode>{`, `}<inlineCode parentName="li">{`n-votes`}</inlineCode>{`, `}<inlineCode parentName="li">{`n-agree`}</inlineCode>{`, and `}<inlineCode parentName="li">{`n-disagree`}</inlineCode></li>
          <li parentName="ul">{`additionally, a column corresponding to each comment in the conversation, collectively serving as a vote-matrix suitable for doing downstream analyses`}</li>
        </ul>
      </li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{``}
        <ul parentName="li">
          <li parentName="ul">{`complete set of comments, with `}<inlineCode parentName="li">{`comment-id`}</inlineCode>{`, `}<inlineCode parentName="li">{`author-id`}</inlineCode>{`, `}<inlineCode parentName="li">{`comment-body`}</inlineCode>{`, `}<inlineCode parentName="li">{`agrees`}</inlineCode>{`, `}<inlineCode parentName="li">{`disagrees`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/votes",
          "title": "votes"
        }}>{`votes`}</a>{``}
        <ul parentName="li">
          <li parentName="ul">{`complete vote history, with `}<inlineCode parentName="li">{`voter-id`}</inlineCode>{`, `}<inlineCode parentName="li">{`comment-id`}</inlineCode>{`, `}<inlineCode parentName="li">{`timestamp`}</inlineCode>{` and `}<inlineCode parentName="li">{`vote`}</inlineCode>{` columns`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Note that the data `}<a parentName="p" {...{
        "href": "/export",
        "title": "export"
      }}>{`export`}</a>{` schema is still considered to be in `}<strong parentName="p">{`BETA`}</strong>{`, and may change in the future.`}</p>
    <h3>{`Summary`}</h3>
    <ul>
      <li parentName="ul">{`This is a very high-level summary of the data about the conversation, including:`}
        <ul parentName="li">
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/Topic",
              "title": "Topic"
            }}>{`Topic`}</a>{` As specified during `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{` creation.`}</li>
          <li parentName="ul"><strong parentName="li">{`URL`}</strong>{` The url of the `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{`.`}</li>
          <li parentName="ul"><strong parentName="li">{`Views`}</strong>{` The number of unique visitors to the `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{`. (Note: this won't necessarily be accurate if people view the conversation with multiple devices but don't log in. For more accurate assessment of this metric, we recommend using Google Analytics on the page in which you embed Polis).`}</li>
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/Voters",
              "title": "Voters"
            }}>{`Voters`}</a>{` Number of `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{` who `}<a parentName="li" {...{
              "href": "/voted",
              "title": "voted"
            }}>{`voted`}</a>{` on at least one `}<a parentName="li" {...{
              "href": "/comment",
              "title": "comment"
            }}>{`comment`}</a>{` in the `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{`.`}</li>
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/Voters",
              "title": "Voters"
            }}>{`Voters`}</a>{` (in conv) Number of `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{` who `}<a parentName="li" {...{
              "href": "/voted",
              "title": "voted"
            }}>{`voted`}</a>{` enough to be represented in the `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{` view. `}{`[*]`}</li>
          <li parentName="ul"><strong parentName="li">{`Commenters`}</strong>{` Number of `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{` who wrote `}<a parentName="li" {...{
              "href": "/comments",
              "title": "comments"
            }}>{`comments`}</a>{`.`}</li>
          <li parentName="ul"><strong parentName="li">{`Groups`}</strong>{` Number of `}<a parentName="li" {...{
              "href": "/opinion-groups",
              "title": "opinion groups"
            }}>{`opinion groups`}</a>{` in the `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{`.`}</li>
          <li parentName="ul"><strong parentName="li">{`Conversation Description`}</strong>{` As specified during `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{` creation.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`[*]`}{` Only `}<a parentName="li" {...{
          "href": "/voters",
          "title": "voters"
        }}>{`voters`}</a>{` who vote on all the `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{` present at the time of voting, or at least 7 `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{`, whichever is less, are represented in the `}<a parentName="li" {...{
          "href": "/visualization",
          "title": "visualization"
        }}>{`visualization`}</a>{`.`}</li>
      <li parentName="ul">{`This table has a separate row for each field.`}</li>
    </ul>
    <h3>{`Stats History`}</h3>
    <ul>
      <li parentName="ul">{`This table is meant to give you more detailed information about the growth of the conversation over time. The 5 columns in this table are:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Votes`}</strong>{` Total number of `}<a parentName="li" {...{
              "href": "/votes",
              "title": "votes"
            }}>{`votes`}</a>{` in the `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{`.`}</li>
          <li parentName="ul"><strong parentName="li">{`Comments`}</strong>{` Number of `}<a parentName="li" {...{
              "href": "/comments",
              "title": "comments"
            }}>{`comments`}</a>{` in the `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{`.`}</li>
          <li parentName="ul"><strong parentName="li">{`Visitors`}</strong>{` Number of visitors to the `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{``}</li>
          <li parentName="ul"><strong parentName="li">{`Voters`}</strong>{` Number of `}<a parentName="li" {...{
              "href": "/voters",
              "title": "voters"
            }}>{`voters`}</a>{` in the `}{`[[conversation]][XXX is this (in conv) or not?]`}</li>
          <li parentName="ul"><strong parentName="li">{`Commenters`}</strong>{` Number of `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{` that wrote `}<a parentName="li" {...{
              "href": "/comments",
              "title": "comments"
            }}>{`comments`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Each row in this table corresponds to a change in one of these values due to an interaction with some participant (a vote, comment, or visit). In the future, we may also include a timestamp indicating when that vote occurred.`}</li>
    </ul>
    <h3>{`Participants Votes`}</h3>
    <ul>
      <li parentName="ul">{`This table provides information about individual participants' voting patterns and opinion group assignment. Participants remain anonymous in this output, and are referred to by an integer ID unique within the conversation.`}</li>
      <li parentName="ul">{`Each row of this table corresponds to a particular participant. The following columns are included:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Participant`}</strong>{` The participant identifier; an integer identifier unique within this conversation.`}</li>
          <li parentName="ul"><strong parentName="li">{`Group ID`}</strong>{` Group ID of the group the participant belongs to.`}</li>
          <li parentName="ul"><strong parentName="li">{`Comments`}</strong>{` Number of comments written by the participant.`}</li>
          <li parentName="ul"><strong parentName="li">{`Votes`}</strong>{` Number of votes by the participant.`}</li>
          <li parentName="ul"><strong parentName="li">{`Agrees`}</strong>{` Number of agree votes by the participant.`}</li>
          <li parentName="ul"><strong parentName="li">{`Disagrees`}</strong>{` Number of disagree votes by the participant.`}</li>
          <li parentName="ul">{`Additionally, for every comment in the conversation, there is a column with that comment's ID as the column name. For a given participant row and comment column, the entry in this table represents the vote that participant gave on that column. A vote of 1 represents agree, a vote of -1 represents disagree, and a vote of 0 represents a pass. If a participant did not vote or pass on the given comment, the corresponding entry is blank.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`In the future, we may add a column indicating whether the participant was included as "In conversation" or not (as described above).`}</li>
    </ul>
    <h3>{`Comments`}</h3>
    <ul>
      <li parentName="ul">{`This table provides summary data for each comment in the conversation. Each row corresponds to a particular comment, with the following columns:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Comment ID`}</strong>{` The comment identifier; an integer identifier unique within this conversation.`}</li>
          <li parentName="ul"><strong parentName="li">{`Author`}</strong>{` Participant ID of the comment's author.`}</li>
          <li parentName="ul"><strong parentName="li">{`Agrees`}</strong>{` Number of agrees on the comment.`}</li>
          <li parentName="ul"><strong parentName="li">{`Disagrees`}</strong>{` Number of disagrees on the comment.`}</li>
          <li parentName="ul"><strong parentName="li">{`Moderated`}</strong>{` Moderation status; 1 for allowed, -1 for moderated out, 0 for unmoderated.`}</li>
          <li parentName="ul"><strong parentName="li">{`Comment Body`}</strong>{` The actual body of the comment.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Note that Comment ID and Author Participant ID correspond with the values in the Participants Votes table.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      